import React, { Component } from "react";
import PropTypes from "prop-types";
import Certificate from "./Certificate";

const propTypes = {
  certificates: PropTypes.array.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  board: PropTypes.oneOf(["individual", "team"]).isRequired,
  profileLink: PropTypes.string,
  user: PropTypes.object,
  handleToggleBoard: PropTypes.func.isRequired,
};

class CertificatesPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.className += " achievementsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("achievementsPage")) {
      bodyDOM.classList.remove("achievementsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderCertificates() {
    const certificates = this.props.certificates;
    return certificates.map((certificate, index) => (
      <Certificate
        key={certificate.certificateId}
        id={certificate.certificateId}
        title={certificate.certificateName}
        img={certificate.certificateTemplateImage}
        userId={this.props.user.id}
        revoked={certificate.certificateRevoked}
        encryptionKey={certificate.certificateUserEncryptedKey}
        url={certificate.certificateUrl}
        language={this.props.language}
        projectId={this.props.projectId}
        sessionKey={this.props.sessionKey}
      />
    ));
  }

  renderProfileHeader() {
    if (this.props.user) {
      return (
        <div className="container verticalpadding darkbg">
          <div className="innerblock">
            <div className="pure-g profile-container flex align-items-center">
              <div className="pure-u-md-1-12 pure-u-1-6">
                <span className="square-image-wrapper">
                  <span className="square-image circle border-gray-light">
                    <img
                      src={this.props.user.photoLarge}
                      alt={this.props.user.name}
                    />
                  </span>
                </span>
                {this.props.user.userRankStatusImage && (
                  <div id="rankStatus" className="photo-icon-wrap">
                    <img
                      className="rank-status"
                      src={this.props.user.userRankStatusImage}
                      alt={this.props.user.userRankStatus}
                    />
                  </div>
                )}
              </div>
              <div className="leftmargin horizontalpadding relative">
                <h5 className="automargin">{this.props.user.name}</h5>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderProfileHeader()}
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 automargin cardlist">
              {this.renderCertificates()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CertificatesPage.propTypes = propTypes;

export default CertificatesPage;
