import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  LOGIN,
  PROJECT_LOGIN,
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN_TOUR,
  PROJECT_HOME,
  PROJECT_SETTINGS,
  SETTINGS,
  PROJECT_BOOKMARKS,
  PROJECT_INBOX,
  PROJECT_SEARCH_CHALLENGES,
  PROJECT_SHARE,
  PROJECT_REFER_A_FRIEND,
} from "App/Routes";
import LogoutContainer from "components/Login/LogoutContainer";
import Link from "components/shared/Link/Link";

import { slide as Menu } from "react-burger-menu";

import {
  ENABLE_INBOX,
  ENABLE_TOUR,
  ENABLE_LOGIN_HOME,
  ENABLE_SETTINGS_PAGES,
  ENABLE_CHALLENGE_BOOKMARK_BUTTON,
  SHOW_HELP_CENTER,
  HELP_CENTER_URL,
  ENABLE_MENU_TOPIC_LIST,
} from "config";
import localize from "lang/localize";
import urlServices from "services/urlServices";
import { SINGLE_PROJECT_APP } from "../../../config";

import packageJson from ".././../../../package.json";
global.appVersion = packageJson.version;

const propTypes = {
  showMenu: PropTypes.bool.isRequired,
  handleShowMenu: PropTypes.func.isRequired,
  handleHideMenu: PropTypes.func.isRequired,
  state: PropTypes.string,
  loggedIn: PropTypes.bool,
  projectId: PropTypes.number,
  project: PropTypes.object,
  topic: PropTypes.object,
  topicsList: PropTypes.array,
  challenge: PropTypes.object,
  inboxUnread: PropTypes.number.isRequired,
  handleOpenSearchChallengeDialog: PropTypes.func,
  handleOpenSelectTopicCategoryDialog: PropTypes.func,
  handleOpenTopicsListDialog: PropTypes.func,
  handleOpenTourModal: PropTypes.func,
  handleOpenShareGameDialog: PropTypes.func,
  handleRefresh: PropTypes.func.isRequired,
  language: PropTypes.string,
  isMobileProjectPage: PropTypes.bool,
};

class TopbarMenu extends Component {
  /**
   * Determine styling for menu components
   */
  setStyles() {
    return {
      bmItem: {
        display: "flex",
        alignItems: "center",
      },
      bmCrossButton: {
        position: "absolute",
        top: "1.5em",
        right: "1.5em",
      },
    };
  }

  /*showSettings(event) {
    event.preventDefault();
  }*/

  isNotProjectSecondary() {
    return this.props.state !== "project-secondary";
  }

  /**
   * Toggle menu open or close
   *
   * @param {*} state
   * @returns
   */
  toggleMenu(state) {
    if (!state.isOpen) {
      return this.handleHideMenu();
    }
  }

  /**
   * Render link to open inbox
   */
  renderInboxItem() {
    if (this.props.loggedIn && this.props.projectId && ENABLE_INBOX) {
      return (
        <Link
          to={PROJECT_INBOX.format(this.props.projectId)}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-inbox notification-icon-wrap">
            {this.props.inboxUnread && this.props.inboxUnread > 0 ? (
              <span className="notification-dot" id="unreadInboxDot" />
            ) : null}
          </i>

          <span>{localize("noun_inbox", this.props.language)}</span>
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to open bookmarks
   */
  renderBookmarkItem() {
    if (
      this.props.loggedIn &&
      this.props.projectId &&
      ENABLE_CHALLENGE_BOOKMARK_BUTTON
    ) {
      return (
        <Link
          to={PROJECT_BOOKMARKS.format(this.props.projectId)}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-bookmark" />
          <span>
            {localize("nav_bar_title_todo_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to open topic list selection dialog
   *
   * @param boolean showTopicsList
   * @returns
   */
  renderTopicsListItem(showTopicsList) {
    if (
      showTopicsList &&
      this.props.projectId &&
      this.isNotProjectSecondary()
    ) {
      return (
        <Link
          to={urlServices.defaultPath()}
          className="menu-item link"
          onClick={(e) => {
            this.props.handleHideMenu();
            this.props.handleOpenTopicsListDialog(e);
          }}
        >
          <i className="fas fa-list" />
          <span>
            {localize("nav_bar_title_games_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to open search dialog
   *
   * @param boolean showSearchChallenges
   * @returns
   */
  renderSearchChallengesItem(showSearchChallenges) {
    if (
      showSearchChallenges &&
      this.props.loggedIn &&
      this.isNotProjectSecondary()
    ) {
      return (
        <a
          className="menu-item link"
          onClick={(e) => {
            this.props.handleHideMenu();
            this.props.handleOpenSearchChallengeDialog(e);
          }}
          href={PROJECT_SEARCH_CHALLENGES.format(this.props.projectId)}
        >
          <i className="fas fa-search" />
          <span>
            {localize("general_search_placeholder_text", this.props.language)}
          </span>
        </a>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to open share dialog
   *
   * @param boolean showShareGame
   * @returns
   */
  renderShareGameItem(showShareGame) {
    if (showShareGame && this.props.projectId) {
      return (
        <a
          className="menu-item link"
          onClick={(e) => {
            this.props.handleHideMenu();
            this.props.handleOpenShareGameDialog(e);
          }}
          href={PROJECT_SHARE.format(this.props.projectId)}
        >
          <i className="fas fa-share-alt" />
          <span>{localize("share_text", this.props.language)}</span>
        </a>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to open 'refer a friend' dialog
   *
   * @param boolean showShareGame
   * @returns
   */
  renderReferAFriendItem(showReferAFriend) {
    if (showReferAFriend && this.props.projectId) {
      return (
        <Link
          to={PROJECT_REFER_A_FRIEND.format(this.props.projectId)}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-user-plus" />
          <span>{localize("project_referral_text", this.props.language)}</span>
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to go back to project
   *
   * @param boolean showBackToProject
   * @param String projectTitle
   * @returns
   */
  renderBackToProjectItem(showBackToProject, projectTitle) {
    if (showBackToProject) {
      return (
        <Link
          to={PROJECT_HOME.format(this.props.projectId)}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-arrow-alt-circle-left" />
          <strong className="back-to-link">
            {SINGLE_PROJECT_APP
              ? localize("icon_home", this.props.language)
              : projectTitle}
          </strong>
        </Link>
      );
    }
  }

  /**
   * Render divider
   *
   * @param boolean showDivider
   * @returns
   */
  renderDivider(showDivider) {
    if (showDivider) {
      return <div className="menu-divider" />;
    } else {
      return null;
    }
  }

  /**
   * Render link to refresh screen
   */
  renderRefreshItem() {
    return (
      <a
        className="menu-item link"
        onClick={(e) => {
          this.props.handleHideMenu();
          this.props.handleRefresh(e);
        }}
        href={urlServices.defaultPath()}
      >
        <i className="fas fa-redo" />
        <span>{localize("refresh_placeholder_text", this.props.language)}</span>
      </a>
    );
  }

  /**
   * Render link to open settings
   */
  renderSettingsItem() {
    if (this.props.loggedIn && ENABLE_SETTINGS_PAGES) {
      return (
        <Link
          to={
            this.props.projectId
              ? PROJECT_SETTINGS.format(this.props.projectId)
              : SETTINGS
          }
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-cog" />
          <span>
            {localize("nav_bar_title_settings_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to open tour
   */
  renderAboutTourItem() {
    if (this.props.state !== "login-tour" && ENABLE_TOUR) {
      return (
        <a
          className="menu-item link"
          onClick={(e) => {
            this.props.handleHideMenu();
            this.props.handleOpenTourModal(e);
          }}
          href={LOGIN_TOUR}
        >
          <i className="fas fa-info-circle" />
          <span>{localize("icon_tour", this.props.language)}</span>
        </a>
      );
    } else {
      return null;
    }
  }

  /**
   * Render link to refresh screen
   */
  renderHelpCenter() {
    if (!SHOW_HELP_CENTER) {
      return null;
    }

    return (
      <a
        className="menu-item link"
        href={HELP_CENTER_URL}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fas fa-question-circle" />
        <span>{localize("link_help_center", this.props.language)}</span>
      </a>
    );
  }

  /**
   * Render link to logout
   */
  renderLogoutItem() {
    if (this.props.loggedIn) {
      return (
        <LogoutContainer
          className="menu-item logout-link"
          handleHideMenu={this.props.handleHideMenu}
        >
          <i className="fas fa-sign-out-alt" />
          <span>{localize("icon_logout", this.props.language)}</span>
        </LogoutContainer>
      );
    } else {
      return null;
    }
  }

  /**
   * Determine login routing
   */
  getLoginRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  /**
   * Render link to login
   */
  renderLoginItem() {
    if (!this.props.loggedIn) {
      return (
        <Link
          to={this.getLoginRoute()}
          className="menu-item"
          onClick={this.props.handleHideMenu}
        >
          <i className="fas fa-sign-in-alt" />
          <span>
            {localize("nav_bar_title_login_text", this.props.language)}
          </span>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderAppVersion() {
    return (
      <div className="menu-item menu-app-version">
        <i className="fas fa-info-circle" />
        <span>
          {localize("app_version", this.props.language)}&nbsp;
          {packageJson.version}
        </span>
      </div>
    );
  }

  /**
   * Render the component
   */
  render() {
    let showTopicsList =
      ENABLE_MENU_TOPIC_LIST &&
      (this.props.state === "projecthome" ||
        this.props.state === "home-single" ||
        this.props.state === "topic-category") &&
      this.props.project &&
      Array.isArray(this.props.topicsList) &&
      this.props.topicsList.length > 0;

    let showSearchChallenges =
      (this.props.state === "projecthome" ||
        this.props.state === "home-single" ||
        this.props.state === "topic-category") &&
      this.props.project &&
      this.props.project.challengeSearchEnabled;

    let showShareGame =
      this.props.project &&
      this.props.project.projectShareLink &&
      this.props.projectId;

    let showReferAFriend =
      this.props.project &&
      this.props.project.projectReferralLink &&
      (this.props.state === "projecthome" ||
        this.props.state === "home-single" ||
        this.props.state === "topic-category" ||
        this.props.state === "project" ||
        this.props.state === "topic" ||
        this.props.state === "topic-comments-thread" ||
        this.props.state === "challenge" ||
        this.props.state === "challenge-category" ||
        this.props.state === "challenge-comments" ||
        this.props.state === "challenge-comments-thread" ||
        this.props.state === "challenge-subpage" ||
        this.props.state === "claim-comments" ||
        this.props.state === "claim-comments-thread");

    /* The pages below (this.props.state) have a route to original Project */
    let showBackToProject =
      this.props.projectId &&
      (this.props.state === "topic" ||
        this.props.state === "topic-comments-thread" ||
        this.props.state === "challenge" ||
        this.props.state === "project" ||
        this.props.state === "project-secondary" ||
        this.props.state === "topic-category" ||
        this.props.state === "challenge-category" ||
        this.props.state === "challenge-comments" ||
        this.props.state === "challenge-comments-thread" ||
        this.props.state === "challenge-subpage" ||
        this.props.state === "claim-comments" ||
        this.props.state === "claim-comments-thread" ||
        this.props.state === "login-tour");

    let projectTitle = this.props.project ? this.props.project.title : "";

    return (
      <Menu
        isOpen={this.props.showMenu}
        handleHideMenu={this.props.handleHideMenu}
        onStateChange={this.toggleMenu}
        styles={this.setStyles()}
        right
      >
        {/* Link to return to parent project */}
        {this.renderBackToProjectItem(showBackToProject, projectTitle)}

        {this.renderTopicsListItem(showTopicsList)}
        {this.renderSearchChallengesItem(showSearchChallenges)}

        {this.renderDivider(showBackToProject || showTopicsList || showSearchChallenges)}

        {/* Logged in links */}
        {this.renderShareGameItem(showShareGame)}
        {this.renderReferAFriendItem(showReferAFriend)}
        {this.renderDivider(
          (showShareGame || showReferAFriend) && this.props.projectId,
        )}

        {this.renderInboxItem()}
        {this.renderBookmarkItem()}

        {/* Can't seem to anchor these to the bottom like Flutter */}
        {this.renderDivider(
          this.props.loggedIn &&
            (ENABLE_INBOX || ENABLE_CHALLENGE_BOOKMARK_BUTTON) &&
            this.props.projectId,
        )}
        {this.renderSettingsItem()}
        {this.renderAboutTourItem()}
        {this.renderHelpCenter()}
        {this.renderLogoutItem()}
        {/* Logged out links */}
        {this.renderLoginItem()}

        {packageJson.version && this.renderDivider(true)}
        {packageJson.version && this.renderAppVersion()}
      </Menu>
    );
  }
}

TopbarMenu.propTypes = propTypes;

export default TopbarMenu;
