import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
  INBOX_PAGE_LINK,
  SHOW_PLATFORM_ANNOUNCEMENT,
  SHOW_NEWS_TAB,
  SHOW_NOTIFICATIONS_TAB,
  SHOW_MESSAGES_TAB,
} from "config";
import deviceServices from "services/deviceServices";
import PostIframe from "../../shared/PostIframe/PostIframe";

const propTypes = {
  sessionKey: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  showTeam: PropTypes.bool,
  language: PropTypes.string,
};

class InboxPage extends Component {
  render() {
    const SHOW_PROJECT_INBOX = !!this.props.projectId;

    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (SHOW_PROJECT_INBOX && this.props.projectId ? " frame-project" : "") +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        {this.props.showTeam ? (
          <Fragment>
            {/* Render Team Inbox */}
            <PostIframe
              hiddenFields={{
                session_key: this.props.sessionKey,
                lang: this.props.language,
                singleProject: SHOW_PROJECT_INBOX,
                ...(SHOW_PROJECT_INBOX && { bundleId: this.props.projectId }),
                showChatTab: SHOW_MESSAGES_TAB,
                showTeamsOnly: this.props.showTeam,
              }}
              url={INBOX_PAGE_LINK}
            />
          </Fragment>
        ) : (
          <Fragment>
            {/* Render Standard Inbox */}
            <PostIframe
              hiddenFields={{
                session_key: this.props.sessionKey,
                lang: this.props.language,
                singleProject: SHOW_PROJECT_INBOX,
                ...(SHOW_PROJECT_INBOX && { bundleId: this.props.projectId }),
                showPlatformAnnouncement: SHOW_PLATFORM_ANNOUNCEMENT,
                showNewsTab: SHOW_NEWS_TAB,
                showNotificationsTab: SHOW_NOTIFICATIONS_TAB,
                showChatTab: SHOW_MESSAGES_TAB,
              }}
              url={INBOX_PAGE_LINK}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

InboxPage.propTypes = propTypes;

export default InboxPage;
