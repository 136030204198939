import React, { Component } from "react";
import { connect } from "react-redux";

import Topbar from "./Topbar";

import {
  setTopbar,
  setProjectId,
  setSessionKey,
  setUser,
  showAlertWithTimeout,
  setProfileDrawer,
} from "actions";
import {
  /* For routing: getBackLink() */
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
  HOME,
  PROJECT_HOME,
  TOPIC,
  CHALLENGE,
  PROJECT_DISCUSSIONS,
} from "App/Routes";

import {
  USE_LOGIN_HOME_TEMPLATE_2,
  ENABLE_LOGIN_HOME,
  ENABLE_LOGIN_TOPBAR,
  ENABLE_TOUR_TOPBAR,
} from "config";
import { VALIDATE_SESSION, GET_INBOX_UNREAD, GET_TOPICS } from "services/api";
import Router from "router";
import pushApiGenerator from "services/pushApiGenerator";
import getApiGenerator from "services/getApiGenerator";
import listenerServices from "services/listenerServices";
import sessionStorageService from "services/sessionStorageService";
import localStorageService from "services/localStorageService";
import { DEFAULT_PACKAGE, DEFAULT_PROJECT } from "services/localStorageService";
import logoutServices from "services/logoutServices";
import localize from "lang/localize";
import packageJson from "../../../package.json";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    user: state.user,
    projectId: state.projectId,
    state: state.topbar.state,
    title: state.topbar.title,
    project: state.topbar.project,
    topicCategory: state.topbar.topicCategory,
    challengeCategory: state.topbar.challengeCategory,
    topic: state.topic,
    challenge: state.topbar.challenge,
    board: state.topbar.board,
    post: state.topbar.post,
    language: state.language,

    /* Rendering inbox unread */
    inboxUnread: state.profileDrawer.inboxUnread,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTopbar: (info) => {
      dispatch(setTopbar(info));
    },
    setSessionKey: (sessionKey) => {
      dispatch(setSessionKey(sessionKey));
    },
    setUser: (user) => {
      dispatch(setUser(user));
    },
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
    setProjectId: (projectId) => {
      dispatch(setProjectId(projectId));
    },
    setProfileDrawer: (info) => {
      dispatch(setProfileDrawer(info));
    },
  };
};

export class TopbarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: undefined,
      showSearchChallengeDialog: false,
      showShareGameDialog: false,
      showTopicsListDialog: false,
      showLanguageSelectionDialog: false,
      showTourModal: false,
      showMenu: false,
      isMobilePage: true, // for defining mobile Project page
      topicsList: null,
      error: null,
      code: null,
    };

    this.handleShowMenu = this.handleShowMenu.bind(this);
    this.handleHideMenu = this.handleHideMenu.bind(this);
    this.handleOpenSearchChallengeDialog =
      this.handleOpenSearchChallengeDialog.bind(this);
    this.handleCloseSearchChallengeDialog =
      this.handleCloseSearchChallengeDialog.bind(this);
    this.handleOpenShareGameDialog = this.handleOpenShareGameDialog.bind(this);
    this.handleCloseShareGameDialog =
      this.handleCloseShareGameDialog.bind(this);
    this.handleOpenTopicsListDialog =
      this.handleOpenTopicsListDialog.bind(this);
    this.handleCloseTopicsListDialog =
      this.handleCloseTopicsListDialog.bind(this);
    this.handleOpenLanguageSelectionDialog =
      this.handleOpenLanguageSelectionDialog.bind(this);
    this.handleCloseLanguageSelectionDialog =
      this.handleCloseLanguageSelectionDialog.bind(this);
    this.handleOpenTourModal = this.handleOpenTourModal.bind(this);
    this.handleCloseTourModal = this.handleCloseTourModal.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRefreshByReplacing = this.handleRefreshByReplacing.bind(this);
  }

  componentDidMount() {
    /*
      Attaching listener for window resizes for rendering
      Topbar in mobile Project page
    */
    listenerServices.addListener("resize", this.setMobilePage);

    /* perform the first check after component is mounted */
    this.setMobilePage();

    /* get topic list */
    if (this.props.projectId) {
      this.getTopics();
    }
  }

  componentWillUnmount() {
    /* Unmount listener for window resizes */
    listenerServices.removeListener("resize", this.setMobilePage);
  }

  setMobilePage = () => {
    const IS_640_DESKTOP_PROJECT =
      sessionStorageService.getItem("640_desktop_project") === "true";
    // 40em corresponds with $md-640 as our .scss variable, or 640px
    // 48em corresponds with $md as our .scss variable, or 768px
    let match = IS_640_DESKTOP_PROJECT
      ? window.matchMedia("(min-width: 40em)")
      : window.matchMedia("(min-width: 48em)");

    if (match.matches) {
      this.setState({ isMobilePage: false });
    } else {
      this.setState({ isMobilePage: true });
    }
  };

  getBackLink() {
    switch (this.props.state) {
      case "resend-activation":
      case "reset-password":
      case "post-login":
        // Project Register or Project Reset Password
        if (this.props.projectId) {
          return PROJECT_LOGIN.format(this.props.projectId);
        }
        // Register or Reset Password
        else {
          return LOGIN;
        }

      case "login":
        if (this.props.projectId && ENABLE_LOGIN_HOME) {
          return PROJECT_LOGIN_HOME.format(this.props.projectId);
        } else if (ENABLE_LOGIN_HOME) {
          return LOGIN_HOME;
        } else {
          return "";
        }

      case "login-secondary":
        // Project Register or Project Reset Password
        if (
          this.props.projectId &&
          ENABLE_LOGIN_HOME &&
          USE_LOGIN_HOME_TEMPLATE_2
        ) {
          return PROJECT_LOGIN_HOME.format(this.props.projectId);
        } else if (this.props.projectId) {
          return PROJECT_LOGIN.format(this.props.projectId);
        }
        // Register or Reset Password
        else if (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_2) {
          return LOGIN_HOME;
        } else {
          return LOGIN;
        }

      case "search-projects":
      case "projects-list":
        return HOME;

      case "projecthome":
        if (this.isEmbeddedProject()) {
          return "";
        } else {
          return HOME;
        }

      case "discussions-posts":
        if (this.props.projectId) {
          if (this.props.board && this.props.board.id) {
            return PROJECT_DISCUSSIONS.format(this.props.projectId);
          } else {
            return PROJECT_HOME.format(this.props.projectId);
          }
        } else {
          return "";
        }

      case "topic":
      case "topic-category":
        if (this.props.projectId) {
          if (this.props.topic && this.props.topic.id) {
            return (
              PROJECT_HOME.format(this.props.projectId) +
              "#topic" +
              this.props.topic.id
            );
          } else {
            return PROJECT_HOME.format(this.props.projectId);
          }
        } else {
          return "";
        }

      case "challenge":
      case "challenge-category":
        if (this.props.topic && this.props.topic.id) {
          if (this.props.challenge && this.props.challenge.id) {
            return (
              TOPIC.format(this.props.topic.id) +
              "#challenge" +
              this.props.challenge.id
            );
          } else {
            return TOPIC.format(this.props.topic.id);
          }
        } else {
          return "";
        }

      case "challenge-comments":
        if (this.props.challenge && this.props.challenge.id) {
          return CHALLENGE.format(this.props.challenge.id);
        } else {
          return "";
        }

      case "discussions-comments":
        if (this.props.projectId) {
          /*
            Unable to differentiate source of link,
            so we can't switch between PROJECT_BOARD_POSTS and PROJECT_DISCUSSIONS.
            Use main page (PROJECT_DISCUSSIONS) for all routing then
          */
          if (this.props.board && this.props.board.id) {
            if (this.props.post && this.props.post.id) {
              // return (
              //   PROJECT_BOARD_POSTS.format(this.props.projectId, this.props.board.id) +
              //   "#post" +
              //   this.props.post.id
              // );
              return (
                PROJECT_DISCUSSIONS.format(this.props.projectId) +
                "#post" +
                this.props.post.id
              );
            } else {
              // Use browser history (no advantage of using discussions link)
              return PROJECT_DISCUSSIONS.format(this.props.projectId);
              // return "";
            }
          } else {
            // Use browser history (no advantage of using discussions link)
            return PROJECT_DISCUSSIONS.format(this.props.projectId);
            // return "";
          }
        } else {
          return "";
        }
      default:
        return "";
    }
  }

  isEmbeddedProject() {
    return sessionStorageService.getItem("embedded_project") === "true";
  }

  handleBack = () => {
    if (this.props.sessionKey) {
      this.invalidateSessionOrGoBack();
    } else {
      window.history.go(-1);
    }
  };

  invalidateSessionOrGoBack = () => {
    let data = {
      app_type: 3,
      app_version: packageJson.version,
    };
    pushApiGenerator(VALIDATE_SESSION, data, this.props.sessionKey).end(
      (err, res) => {
        if (
          err ||
          res.body.code !== 200 ||
          (this.props.userId && this.props.userId !== res.body.userId)
        ) {
          logoutServices.sessionLogout(
            this.props.projectId,
            this.props.language,
            this.props.setSessionKey,
            this.props.setUser,
            this.props.showAlertWithTimeout,
          );
        } else {
          // Update package/project id in case changed by admin
          if (res.body.gameId) {
            // single project app
            localStorageService.setItem(DEFAULT_PROJECT, res.body.gameId);
            this.props.setProjectId(res.body.gameId);
          } else if (res.body.entryProjectId) {
            // either multi-project app or project assignment
            localStorageService.setItem(
              DEFAULT_PROJECT,
              res.body.entryProjectId,
            );
            this.props.setProjectId(res.body.entryProjectId);
          }
          if (res.body.masterId) {
            // multi-project app (package)
            localStorageService.setItem(DEFAULT_PACKAGE, res.body.masterId);
          }

          window.history.go(-1);
        }
      },
    );
  };

  /**
   * Retrieve list of unread notifications from API
   */
  getInboxUnread(project_id) {
    if (this.props.user.id === undefined) {
      return false;
    }
    getApiGenerator(
      GET_INBOX_UNREAD,
      { bundle_id: project_id },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
      } else {
        this.props.setProfileDrawer({ inboxUnread: res.body.unreadCount });
      }
    });
  }

  /**
   * Retrieve list of topics from API
   */
  getTopics() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topicsList: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          topicsList: res.body.data,
          code: res.body.code,
          error: "",
        });
      }
    });
  }

  handleShowMenu() {
    this.setState({
      showMenu: true,
    });
  }

  handleHideMenu() {
    this.setState({
      showMenu: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projectId !== this.state.projectId) {
      this.setState({ projectId: this.props.projectId });
      if (this.props.projectId) {
        this.getTopics();

        if (this.props.sessionKey) {
          this.getInboxUnread(this.props.projectId);
        }
      }
    }
  }

  isHome() {
    return (
      this.props.state === "home" ||
      this.props.state === "home-single" ||
      this.props.state === "home-multi"
    );
  }

  isLoggedIn() {
    if (this.props.user && this.props.sessionKey) {
      return true;
    } else {
      return false;
    }
  }

  handleOpenSearchChallengeDialog(event) {
    event.preventDefault();

    this.setState({
      showSearchChallengeDialog: true,
    });
  }

  handleCloseSearchChallengeDialog() {
    this.setState({
      showSearchChallengeDialog: false,
    });
  }

  handleOpenShareGameDialog(event) {
    event.preventDefault();

    this.setState({
      showShareGameDialog: true,
    });
  }

  handleCloseShareGameDialog() {
    this.setState({
      showShareGameDialog: false,
    });
  }

  /**
   * Open topic list popup dialog
   *
   * @param {object} event - Event
   */
  handleOpenTopicsListDialog(event) {
    event.preventDefault();

    this.setState({
      showTopicsListDialog: true,
    });
  }

  /**
   * Close topic list popup dialog
   */
  handleCloseTopicsListDialog() {
    this.setState({
      showTopicsListDialog: false,
    });
  }

  handleOpenLanguageSelectionDialog() {
    this.setState({
      showLanguageSelectionDialog: true,
    });
  }

  handleCloseLanguageSelectionDialog() {
    this.setState({
      showLanguageSelectionDialog: false,
    });
  }

  handleOpenTourModal(event) {
    event.preventDefault();

    this.setState({
      showTourModal: true,
    });
  }

  handleCloseTourModal() {
    this.setState({
      showTourModal: false,
    });
  }

  handleRefresh(event) {
    event.preventDefault();

    /*
      Project view and Topic view URLs may be hashed, so special handling
      through handling refreshes by resetting the URL and forcing a reload
      is necessary.
    */
    if (
      this.props.state === "home-single" ||
      this.props.state === "projecthome"
    ) {
      setTimeout(
        this.handleRefreshByReplacing(
          PROJECT_HOME.format(this.props.projectId),
        ),
        100,
      );
    } else if (this.props.state === "topic") {
      setTimeout(
        this.handleRefreshByReplacing(TOPIC.format(this.props.topic.id)),
        100,
      );
    } else {
      setTimeout(function () {
        window.location.reload(true);
      }, 100);
    }
  }

  handleRefreshByReplacing(route) {
    return function () {
      Router.replaceAndNavigate(route);
      window.location.reload(true);
    };
  }

  /**
   * Renders the topbar title based on current app location.
   */
  setTitle() {
    switch (this.props.title) {
      case "_LOGIN":
        return localize("nav_bar_title_login_text", this.props.language);
      case "_REGISTER":
        return localize("login_signup_web_view_title", this.props.language);
      case "_LOGIN_TOUR":
        return localize("icon_tour", this.props.language);
      case "_PROJECTS_LIST":
        return localize("nav_bar_title_bundles_text", this.props.language);
      case "_PROFILE":
      case "_USER":
      case "_PROJECT_PROFILE":
      case "_PROJECT_USER":
        return localize("nav_bar_title_user_profile_text", this.props.language);
      case "_PROJECT_USERS":
        return localize("nav_bar_title_players_text", this.props.language);
      case "_ACHIEVEMENTS":
      case "_USER_ACHIEVEMENTS":
      case "_PROJECT_ACHIEVEMENTS":
      case "_PROJECT_USER_ACHIEVEMENTS":
        return localize("nav_bar_title_items_text", this.props.language);
      case "_USER_FOLLOWS":
      case "_PROJECT_USER_FOLLOWS":
        return localize("nav_bar_follows_text", this.props.language);
      case "_PROJECT_USER_ENQUIRY":
        return localize("report_user", this.props.language);
      case "_PROJECT_ACTIVITY":
        return localize("menu_bar_activity_text", this.props.language);
      case "_CHALLENGE_ACTIVITY":
        return localize(
          "nav_bar_title_claim_profile_text",
          this.props.language,
        );
      case "_CHALLENGE_ENQUIRY":
        return localize("report_challenge", this.props.language);
      case "_CLAIM":
        return localize("nav_bar_title_claims_text", this.props.language);
      case "_CLAIM_ENQUIRY":
        return localize("report_submission", this.props.language);
      case "_CLAIM_COMMENTS":
        return localize("comments_novariable_text", this.props.language);
      case "_BOOKMARKS":
      case "_PROJECT_BOOKMARKS":
        return localize("nav_bar_title_todo_text", this.props.language);
      case "_NOTIFICATIONS":
      case "_PROJECT_NOTIFICATIONS":
        return localize(
          "nav_bar_title_notifications_text",
          this.props.language,
        );
      case "_INBOX":
      case "_PROJECT_INBOX":
        return localize("noun_inbox", this.props.language);
      case "_PROJECT_LEADERBOARD":
        return localize("button_home_leaderboard", this.props.language);
      case "_PROJECT_REFER_A_FRIEND":
        return localize("project_referral_text", this.props.language);
      case "_PROJECT_REWARDS":
        return localize("nav_bar_title_empty_store_text", this.props.language);
      case "_PROJECT_TEAMS":
        return localize("nav_bar_title_teams_text", this.props.language);
      case "_PROJECT_TEAM_CREATE":
        return localize("new_team_text", this.props.language);
      case "_TEAM_INVITE":
        return localize("invite_players_text", this.props.language);
      case "_TEAM_UPDATE":
        return localize("update_team_text", this.props.language);
      case "_PROJECT_HOME":
        return localize("nav_bar_title_games_text", this.props.language);
      case "_TOPIC_CATEGORY":
        return localize("icon_categories", this.props.language);
      case "_TOPIC_COMMENTS":
        return "_TOPIC_COMMENTS";
      case "_TOPIC_COMMENTS_THREAD":
      case "_CHALLENGE_COMMENTS_THREAD":
      case "_CLAIM_COMMENTS_THREAD":
        return localize("nav_bar_title_thread_text", this.props.language);
      case "_PROJECT_SEARCH":
        return localize("general_search_placeholder_text", this.props.language);
      case "_CHALLENGE":
        return localize(
          "nav_bar_title_claim_challenge_text",
          this.props.language,
        );
      case "_CHALLENGE_A_FRIEND":
        return localize("challenge_referral_text", this.props.language);
      case "_ITEM":
        /*
          Default topbar title is set in Pages.js, under topbarTitle() as "_ITEM".
          "_ITEM" points to button_home_rewards in setTitle() in TopbarContainer.js (default).
          This is further differentiated between Rewards and Achievements during a successful
          API call in ItemContainer.js, in getItem().
        */
        return localize("button_home_rewards", this.props.language);
      case "_TEAM":
        return localize("nav_bar_title_team_profile_text", this.props.language);
      case "_SETTINGS":
        return localize("nav_bar_title_settings_text", this.props.language);
      case "_ERROR":
        return localize("error_text", this.props.language);
      /* For Gametize app layout */
      case "_HOME":
        return localize("icon_home", this.props.language);
      case "_SEARCH_PROJECTS":
        return localize("general_search_placeholder_text", this.props.language);
      case "_FEATURED_PROJECTS":
        return localize("nav_bar_title_sub_featured", this.props.language);
      case "_MY_PROJECTS":
        return localize("nav_bar_title_my_bundles_text", this.props.language);
      case "_GAME":
        return localize("gametize_nav_bar_title_game", this.props.language);
      default:
        return null;
    }
  }

  /**
   * Renders the component
   */
  render() {
    if (
      this.props.state === "no-topbar" ||
      (!ENABLE_LOGIN_TOPBAR &&
        (this.props.state === "login" || this.props.state === "login-home")) ||
      (!ENABLE_TOUR_TOPBAR && this.props.state === "login-tour")
    ) {
      return null;
    } else {
      return (
        <Topbar
          showMenu={this.state.showMenu}
          title={this.setTitle()}
          state={this.props.state}
          isMobileProjectPage={
            this.state.isMobilePage &&
            (this.props.state === "home-single" ||
              this.props.state === "projecthome")
          }
          isHome={this.isHome()}
          isEmbeddedProject={this.isEmbeddedProject()}
          loggedIn={this.isLoggedIn()}
          user={this.props.user}
          inboxUnread={this.props.inboxUnread || 0}
          projectId={this.props.projectId}
          handleBack={this.handleBack}
          backLink={this.getBackLink()}
          project={this.props.project}
          topicCategory={this.props.topicCategory}
          challengeCategory={this.props.challengeCategory}
          topic={this.props.topic}
          topicsList={this.state.topicsList}
          challenge={this.props.challenge}
          board={this.props.board}
          handleShowMenu={this.handleShowMenu}
          handleHideMenu={this.handleHideMenu}
          showSearchChallengeDialog={this.state.showSearchChallengeDialog}
          handleOpenSearchChallengeDialog={this.handleOpenSearchChallengeDialog}
          handleCloseSearchChallengeDialog={
            this.handleCloseSearchChallengeDialog
          }
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          showTopicsListDialog={this.state.showTopicsListDialog}
          handleOpenTopicsListDialog={this.handleOpenTopicsListDialog}
          handleCloseTopicsListDialog={this.handleCloseTopicsListDialog}
          showLanguageSelectionDialog={this.state.showLanguageSelectionDialog}
          handleOpenLanguageSelectionDialog={
            this.handleOpenLanguageSelectionDialog
          }
          handleCloseLanguageSelectionDialog={
            this.handleCloseLanguageSelectionDialog
          }
          showTourModal={this.state.showTourModal}
          handleOpenTourModal={this.handleOpenTourModal}
          handleCloseTourModal={this.handleCloseTourModal}
          handleRefresh={this.handleRefresh}
          language={this.props.language}
          sessionKey={this.props.sessionKey}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);
