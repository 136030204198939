import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./MobileProject.css";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import TopicCard from "./TopicCard";
import scrollToId from "library/js/scroll";

import { SET_PROJECT_BG_IMG, SHOW_TOPIC_CATEGORIES } from "config";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number,
  projectImage: PropTypes.string,
  buttons: PropTypes.array,
  topics: PropTypes.array.isRequired,
  user: PropTypes.object,
  team: PropTypes.object,
  categoryId: PropTypes.number,
  categories: PropTypes.array,
  notifications: PropTypes.number.isRequired,
  inboxUnread: PropTypes.number.isRequired,
  more: PropTypes.bool,
  randomNumber: PropTypes.number.isRequired,
  showcaseProject: PropTypes.object,
  addAnchors: PropTypes.func,
  handleMore: PropTypes.func,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  renderTopics: PropTypes.func,
  renderTopicCategories: PropTypes.func,
  language: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func,
};

const defaultProps = {
  projectImage: placeholder_project,
  projectId: null,
};

class MobileProjectPage extends Component {
  /*
    react-slick's initialSlide does not pair well with React's re-renders.
    Thus, you want to use componentDidUpdate and slickGoTos for allowing
    the slider to jump to the first Topic card or the Project Title card
    in a Topic Category page.
  */
  componentDidUpdate(prevProps) {
    if (
      this.props.categoryId &&
      this.props.categoryId !== prevProps.categoryId
    ) {
      scrollToId("topicCategoryHeaderMobile", 15, 500);
    }
  }

  componentDidMount() {
    this.scrollToTopicIdFromHash();

    if (this.props.categoryId && window.location.hash.indexOf("#topic") < 0) {
      scrollToId("topicCategoryHeaderMobile", 15, 500);
    }
  }

  /**
   * Scroll to current Topic ID based on URL hash
   *
   * Constraints:
   * 1) Unable to auto scroll beyond page 1
   * 2) May have race condition with list loading
   */
  scrollToTopicIdFromHash() {
    /*
      Will not use parseInt() here first, because parseInt("123xyz", 10) === 123.
      Employing stricter controls by directly checking isNaN on the string.
    */
    let topicHashId =
      window.location.hash.indexOf("#topic") === 0
        ? window.location.hash.slice(6)
        : null;

    if (window.location.hash.indexOf("#topicreset") === 0) {
      topicHashId = window.location.hash.slice(14);
    }

    if (topicHashId) {
      /*
        1) Unable to auto scroll beyond page 1
        2) Using setTimeout to get around race condition
           - Delay should be above 250ms (default in router.js)
           - There is an event that is scrolling page to top, so we use a different ID from anchor in URL
        3) Use margin of 10px so it doesn't look like it's stuck to topbar
      */
      if (document.getElementById("anchoredTopicMobile" + topicHashId)) {
        scrollToId("anchoredTopicMobile" + topicHashId, 10, 500);
      }
    }
  }

  /**
   * Retrieve Topic index by Topic ID from an array of Topics
   * @param {int} topicIdKey - Topic ID
   * @param {object} array - Array of Topics
   * @returns
   */
  searchTopicIndexByTopicId(topicIdKey, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === topicIdKey) {
        return i;
      }
    }
  }

  /**
   * Scroll to Project Information screen
   */
  scrollToProjectInfo() {
    // Use ID appPageBase instead of projectInformationContainer to ensure
    // screen scrolls directly to the top
    let screen = document.getElementById("appPageBase");
    screen && screen.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Scroll to Topic List screen
   */
  scrollToTopics() {
    // Use margin of 5px so it doesn't look like it's stuck to topbar
    scrollToId("topicListContainer", 5, 500);
  }

  /**
   * Render list of Topics in the Project
   */
  renderTopicCards() {
    return (
      <div
        className="pure-u-1 mobile-project-topics-wrapper"
        id="topicListContainer"
      >
        {this.props.renderTopics(this.props.topics, true)}
        {!this.props.more &&
          !!this.props.showcaseProject &&
          this.renderProjectShowcaseCard()}
      </div>
    );
  }

  renderCategory() {
    if (!this.props.categoryId) {
      return null;
    }

    let category = this.props.categories.filter(
      (category) =>
        category.hasOwnProperty("selected") && category.selected === true,
    )[0];

    let categoryTitle = category ? category.title : "";
    let anchoredCategoryTitle =
      categoryTitle !== "" ? this.props.addAnchors(categoryTitle) : "";

    let categoryDescription = category ? category.description : "";
    let anchoredCategoryDescription =
      categoryDescription !== ""
        ? this.props.addAnchors(categoryDescription)
        : "";

    return (
      <div className={category ? "category-container" : null}>
        <div id="topicCategoryHeaderMobile" className="category-title-icon">
          {/* Commented out the below until Topic Category icons are supported */}
          {/*{category &&
            category.image &&
            this.renderCategoryImage(category.image)}
          {category && category.image && (
            <div className="pure-u-1 pure-u-sm-1-12" />
          )}
          <div
            className={
              "pure-u-1 " +
              (category && category.image
                ? "pure-u-sm-19-24 "
                : "pure-u-sm-1-1 ")
            }
          >*/}

          {/* When Topic Category icons are supported, remove the 3 lines below and uncomment the lines above */}
          <h3
            className="category-title"
            dangerouslySetInnerHTML={{ __html: anchoredCategoryTitle }}
          />
        </div>
        <div className="category-description">
          <p
            dangerouslySetInnerHTML={{ __html: anchoredCategoryDescription }}
          />
        </div>
      </div>
    );
  }

  /**
   * Render project information
   *
   * @param {object} project - The project object
   */
  renderProjectInfo(project) {
    let anchoredProjectTitle = this.props.addAnchors(project.title);
    let anchoredProjectDescription = this.props.addAnchors(project.description);
    let projectImage = this.props.projectImage;

    return (
      <div className="pure-u-1" id="projectInformationContainer">
        <div className="mobile-project-title-wrapper">
          <div
            id="project"
            className="pure-g horizontalpadding-10 topmargin-30"
          >
            <div className="pure-u-1">
              <div className="pure-u-1-2 imgcenter">
                <span className="square-image-wrapper bottommargin-30">
                  <span className="square-image circle">
                    <img src={projectImage} alt={project.title} />
                  </span>
                </span>
              </div>
              <div className="mobile-project-description-wrapper">
                <div className="mobile-project-description-content">
                  <h4
                    className="textcenter"
                    dangerouslySetInnerHTML={{ __html: anchoredProjectTitle }}
                  />
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: anchoredProjectDescription,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render Showcase Topic Card for current Project
   */
  renderProjectShowcaseCard() {
    let showcaseImage = this.props.showcaseProject.showcaseImage;
    let showcaseTitle = this.props.showcaseProject.showcaseTagline;
    let showcaseDescription = this.props.showcaseProject.showcaseDescription;
    let showcaseProjectId = this.props.showcaseProject.projectId;

    return (
      <TopicCard
        sessionKey={this.props.sessionKey}
        projectId={this.props.projectId}
        isMobile={true}
        title={showcaseTitle}
        description={showcaseDescription}
        img={showcaseImage}
        id={showcaseProjectId}
        showcase={true}
        completed={false}
        language={this.props.language}
        totalChallenges={0}
        completedChallenges={0}
        started={false}
        locked={false}
        expired={false}
      />
    );
  }

  render() {
    let projectBg = this.props.project.backgroundImage;

    return (
      <Fragment>
        <div
          className="mobile-project-wrapper"
          style={
            SET_PROJECT_BG_IMG && projectBg
              ? { backgroundImage: `url(${projectBg})` }
              : null
          }
        >
          {this.renderProjectInfo(this.props.project)}
          {SHOW_TOPIC_CATEGORIES && this.props.renderTopicCategories()}
          {SHOW_TOPIC_CATEGORIES &&
            this.props.categories &&
            this.renderCategory()}
          {this.renderTopicCards()}
        </div>
      </Fragment>
    );
  }
}

MobileProjectPage.propTypes = propTypes;
MobileProjectPage.defaultProps = defaultProps;

export default MobileProjectPage;
