import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Router from "router";

import "./TopicPage.css";
import {
  PROJECT_ACHIEVEMENTS,
  TOPIC_COMMENTS,
  TOPIC,
  CHALLENGE_CATEGORY,
} from "App/Routes";
import ChallengeCardContainer from "./ChallengeCardContainer";
import TopicAchievement from "./TopicAchievement";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";
import Link from "components/shared/Link/Link";
import PersonalityModalButton from "components/Topic/PersonalityModal/PersonalityModalButton";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import ResetActivityDialogContainer from "components/shared/Dialogs/ResetActivityDialog/ResetActivityDialogContainer";
import scrollToId from "library/js/scroll";

import {
  ENABLE_TOPIC_COMMENTS,
  SHOW_CHALLENGE_CATEGORIES,
} from "config";
import { getChallengeType, getPoints } from "services/challengeServices";
import localize from "lang/localize";

import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import Loading from "components/shared/Loading";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  topicIdNext: PropTypes.number,
  sessionKey: PropTypes.string,
  project: PropTypes.object,
  topic: PropTypes.object,
  challenges: PropTypes.array.isRequired,
  categoryId: PropTypes.number,
  achievements: PropTypes.array,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  totalChallenges: PropTypes.number,
  completedChallenges: PropTypes.number,
  personality: PropTypes.object,
  allowReset: PropTypes.bool.isRequired,
  isSearch: PropTypes.bool,
  showTopicChallengesCompleted: PropTypes.bool,
  showTopicAchievements: PropTypes.bool,
  topicTitle: PropTypes.string,
  topicId: PropTypes.number,
  showLoginDialog: PropTypes.bool,
  handleOpenLoginDialog: PropTypes.func,
  handleCloseLoginDialog: PropTypes.func,
  showResetActivityDialog: PropTypes.bool,
  handleOpenResetActivityDialog: PropTypes.func,
  handleCloseResetActivityDialog: PropTypes.func,
  handleCategorySelect: PropTypes.func,
  isLoggedIn: PropTypes.bool.isRequired,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
};

const defaultProps = {
  more: false,
  completedChallenges: 0,
  totalChallenges: 0,
  personality: undefined,
  isSearch: false,
  topicIdNext: null,
};

class TopicPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("topicPage")) {
      bodyDOM.className += " topicPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }

    this.scrollToChallengeIdFromHash();
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("topicPage")) {
      bodyDOM.classList.remove("topicPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Scroll to anchored challenge
   *
   * Constraints:
   * 1) Unable to auto scroll beyond page 1
   * 2) May have race condition with list loading
   */
  scrollToChallengeIdFromHash() {
    let challengeHashId =
      window.location.hash.indexOf("#challenge") === 0
        ? window.location.hash.slice(10)
        : null;

    if (challengeHashId) {
      /*
        1) Unable to auto scroll beyond page 1
        2) Using setTimeout to get around race condition
           - Delay should be above 250ms (default in router.js)
           - There is an event that is scrolling page to top, so we use a different ID from anchor in URL
        3) Use margin of 30px so it doesn't look like it's stuck to topbar
           - Extra 10px because of challenge type icons
           - Need extra pixels to offset topic progress bar in mobile
             - Add progress bar height
             - Buffer another 20px
      */
      let paddedHeight = 30;

      if (window.innerWidth < 768 && document.getElementById("progressbar")) {
        let progressBarHeight =
          document.getElementById("progressbar").offsetHeight;
        paddedHeight += progressBarHeight + 20;
      }

      scrollToId("anchoredChallenge" + challengeHashId, paddedHeight, 300);
    }
  }

  /**
   * Get unlockable status of a single challenge
   *
   * @param {boolean} unlockable - Is challenge unlockable
   * @param {boolean} locked - Is challenge locked
   * @return {boolean} Lock status
   */
  getUnlocked(unlockable, locked) {
    return !unlockable || locked === false;
  }

  renderChallengeCategories() {
    if (SHOW_CHALLENGE_CATEGORIES && this.props.topic.categories
      && Array.isArray(this.props.topic.categories)
      && this.props.topic.categories.length > 0) {
        let currentCategory = this.props.topic.categories.filter(
          (category) =>
            category.hasOwnProperty("selected") && category.selected === true,
        )[0];

        return (
          <div id="categoryChips" className="category-chips chips">
            {/* Indicate all challenges */}
            {!currentCategory && (
              <Fragment key={"challenges-all"}>
                <button className="category-chip chip category-all button inline small active">
                  <span
                    className="category-title chip-title"
                    title={localize("category_all_text", this.props.language)}
                  >
                    {localize("category_all_text", this.props.language)}
                  </span>
                </button>
              </Fragment>
            )}
            {/* Show button to go to clear category selection */}
            {currentCategory && (
              <Fragment key={"challenges-all"}>
                <Link
                  className="category-chip chip category-all button inline small inactive"
                  to={TOPIC.format(this.props.topicId)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleCategorySelect(0);
                  }}
                >
                  <span
                    className="category-title chip-title"
                    title={localize("category_all_text", this.props.language)}
                  >
                    {localize("category_all_text", this.props.language)}
                  </span>
                </Link>
              </Fragment>
            )}
            {/* Display current category at second position */}
            {currentCategory && (
              <Fragment key={this.props.id}>
                <button className="category-chip chip button inline small active">
                  <span
                    id={"categoryChip" + currentCategory.id}
                    className="category-title chip-title"
                    title={currentCategory.title}
                    dangerouslySetInnerHTML={{
                      __html: currentCategory.title,
                    }}
                  />
                </button>
              </Fragment>
            )}
            {/* Display other categories */}
            {this.props.topic.categories.map(
              (category) =>
                (currentCategory == null || currentCategory.id !== category.id) && (
                  <Fragment key={category.id}>
                    <Link
                      id={"categoryChip" + category.id}
                      className="category-chip chip button inline small inactive"
                      to={CHALLENGE_CATEGORY.format(
                        this.props.topicId,
                        category.id,
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.handleCategorySelect(category.id);
                      }}
                    >
                      <span
                        className="category-title chip-title"
                        title={category.title}
                        dangerouslySetInnerHTML={{
                          __html: category.title,
                        }}
                      />
                    </Link>
                  </Fragment>
                ),
            )}
          </div>
        );
    } else {
      return null;
    }
  }

  /**
   * Render list of challenge cards
   *
   * @param {object} challenges - JSON data object
   */
  renderChallenges(challenges) {
    return (
      <div className="pure-g">
        {challenges.map((challenge, index) => (
          <div className="pure-u-1 pure-u-md-12-24" key={index}>
            <div className="pure-u-sm-1-24" />
            <div className="pure-u-sm-22-24">
              <ChallengeCardContainer
                id={challenge.id}
                unlocked={this.getUnlocked(
                  challenge.unlockable,
                  challenge.locked,
                )}
                title={challenge.title}
                img={challenge.imageSmall}
                challengeType={getChallengeType(
                  challenge.challengeTypeId,
                  challenge.photoOnly,
                  challenge.multiSelect,
                  challenge.challengeType,
                )}
                challengeTypeId={challenge.challengeTypeId}
                points={getPoints(challenge.challengeTypeId, challenge.points)}
                isFlashcard={challenge.challengeTypeId === 4}
                isConfirmationChallenge={
                  challenge.challengeTypeId === 11 &&
                  challenge.confirmation === true
                }
                challengeReferralLink={challenge.challengeReferralLink}
                likeNo={challenge.likeNo}
                completedNo={challenge.claimNo}
                isLiked={!!challenge.ratedLike}
                isBookmarked={!!challenge.bookmarked}
                isCompleted={!!challenge.claimed}
                isExpired={!!challenge.expired}
                handleLike={(event) =>
                  this.props.handleLike(event, challenge.id)
                }
                handleBookmark={(event) =>
                  this.props.handleBookmark(
                    event,
                    challenge.id,
                    challenge.bookmarked,
                  )
                }
                handleOpenLoginDialog={this.props.handleOpenLoginDialog}
                isLoggedIn={this.props.isLoggedIn}
                language={this.props.language}
                contactEmail={this.props.contactEmail}
              />
            </div>
            <div className="pure-u-sm-1-24" />
          </div>
        ))}
      </div>
    );
  }

  /**
   * Render topic progress bar
   */
  renderProgressBar() {
    if (!this.props.isSearch) {
      return (
        <ProgressBar
          id="progressbar"
          className="bottommargin boxshadow"
          completed={this.props.completedChallenges}
          total={this.props.totalChallenges}
          language={this.props.language}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Render achievements earnable
   */
  renderAchievements() {
    if (!this.props.isSearch) {
      return (
        <div
          className={
            "pure-g" +
            (this.props.achievements.length >= 1 ? " bottommargin-5" : "")
          }
          id="achievements"
        >
          <div className="pure-u-1-24" />
          <div className="pure-u-22-24">
            {this.props.achievements.map((achievement, index) => (
              <Link
                to={PROJECT_ACHIEVEMENTS.format(this.props.project.id)}
                key={achievement.id}
              >
                <TopicAchievement
                  img={achievement.imageSmall}
                  earned={achievement.earned}
                  isFifthItem={index === 4}
                />
              </Link>
            ))}
          </div>
          <div className="pure-u-1-24" />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render elememts for personality quiz-type topics
   */
  renderPersonality() {
    if (this.props.personality) {
      let tag = this.props.personality.tag;

      return (
        <div
          id="personalityResultSegment"
          className="content-box-background-dark bottommargin-10"
        >
          <div className="textcenter">
            <h3 className="nomargin">
              {localize("tag_result_text", this.props.language) + ": "}
            </h3>
            <h4>
              <span>{tag}</span>
            </h4>
          </div>

          <PersonalityModalButton
            text={localize("button_view_personality", this.props.language)}
            topicId={this.props.topicId}
            personality={this.props.personality}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render prompt login popup dialog
   */
  renderLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLoginDialog}
      />
    );
  }

  /**
   * Render confirmation dialog for resetting activity
   */
  renderResetActivityDialog() {
    return (
      <ResetActivityDialogContainer
        context="topic"
        showDialog={this.props.showResetActivityDialog}
        handleCloseDialog={this.props.handleCloseResetActivityDialog}
        topicTitle={this.props.topicTitle || ""}
        topicId={this.props.topicId}
        projectId={this.props.project.id}
      />
    );
  }

  /**
   * Render top section of page - doesn't appear for challenge category view
   *
   * Comprises:
   * - Activity reset buttons for other topic types
   * - Progress bar
   */
  renderTopSection() {
    if (
      !this.props.categoryId &&
      this.props.allowReset &&
      this.props.sessionKey
    ) {
      /* with dropdown button for reset activity, and sticky progress bar */
      return (
        <div className="pure-g position-sticky-offset-topbar hide-from-lg bottommargin-10">
          <div className="pure-u-20-24 pure-u-sm-22-24 box-sizing-border-box-all flex align-items-center">
            {this.props.showTopicChallengesCompleted &&
              this.renderProgressBar()}
          </div>
          <div className="pure-u-4-24 pure-u-sm-2-24 box-sizing-border-box-all flex justify-content-flexend align-items-center">
            <div className="dropdown-button-group main-topic-button-dropdown">
              <Dropdown
                dropup={false}
                onSelect={(eventKey, event) => {
                  event.preventDefault();
                  this.props.handleOpenResetActivityDialog();
                }}
                pullRight={true}
              >
                <Dropdown.Toggle
                  btnStyle="flat"
                  noCaret={true}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span className="more-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <MenuItem eventKey={1} onSelect={(eventKey, event) => {}}>
                    {localize("reset_topic", this.props.language)}
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {this.renderResetActivityDialog()}
        </div>
      );
    } else if (!this.props.categoryId) {
      /* without dropdown button for reset activity, and progress bar */
      return (
        <div className="pure-g position-sticky-offset-topbar pure-u-lg-0 bottommargin-10">
          <div className="pure-u-1-1 box-sizing-border-box-all horizontalpadding-5 flex align-items-center">
            {this.props.showTopicChallengesCompleted &&
              this.renderProgressBar()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render topic navigation tab bar - toggle between challenges and comments
   */
  renderTopicCommentsNav() {
    if (
      !this.props.categoryId &&
      !this.props.isSearch &&
      ENABLE_TOPIC_COMMENTS
    ) {
      return (
        <div className="container verticalpadding">
          <nav className="nav-buttons">
            <button className="button active">
              <span className="capitalize">
                <i className="fas fa-th-list"></i>
                {localize("challenges_novariable_text", this.props.language)}
              </span>
            </button>
            <Link
              to={TOPIC_COMMENTS.format(this.props.topicId)}
              className="button inactive"
            >
              <span className="capitalize">
                <i className="fas fa-comment"></i>
                {localize("comments_novariable_text", this.props.language)
                  .format("")
                  .trim()}
              </span>
            </Link>
          </nav>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render view
   */
  render() {
    return (
      <Fragment>
        {this.renderTopicCommentsNav()}
        <div className="topic-container container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 hide-below-sm pure-u-sm-2-24" />
            <div className="pure-u-1 pure-u-sm-20-24">
              {this.renderTopSection()}
              {this.props.showTopicAchievements && this.renderAchievements()}
              {this.renderPersonality()}
              {this.props.showLoginDialog && this.renderLoginDialog()}
              {((this.props.personality || this.props.showTopicAchievements)
                && SHOW_CHALLENGE_CATEGORIES && this.props.topic.categories
                && Array.isArray(this.props.topic.categories)
                && this.props.topic.categories.length > 0) && <div className="category-margin" />}
              {SHOW_CHALLENGE_CATEGORIES && this.renderChallengeCategories()}
              {this.renderChallenges(this.props.challenges)}
              {this.props.isLoadingMore && <Loading />}
              {this.props.topicIdNext && !this.props.more && (
                <button
                  id="nextButton"
                  className="button automargin link cta lowercase topic-next-button"
                  onClick={() => {
                    Router.navigate(TOPIC.format(this.props.topicIdNext));
                  }}
                >
                  {localize("button_next_topic", this.props.language)}
                </button>
              )}
            </div>
            <div className="pure-u-1 hide-below-sm pure-u-sm-2-24" />
          </div>
        </div>
      </Fragment>
    );
  }
}

TopicPage.propTypes = propTypes;
TopicPage.defaultProps = defaultProps;

export default TopicPage;
