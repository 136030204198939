import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./Project.css";
import TopicCard from "./TopicCard";
import PopupAlertDialog from "./PopupAlertDialog/PopupAlertDialog";
import ShareGameDialog from "components/shared/Dialogs/ShareGameDialog/ShareGameDialog";
import DesktopProjectPage from "./DesktopProjectPage";
import MobileProjectPage from "./MobileProjectPage";
import Link from "components/shared/Link/Link";

import Modal from "react-modal";
import Router from "router";
import { PROJECT_HOME, CHALLENGE, TOPIC_CATEGORY } from "App/Routes";
import { SHOW_TOPIC_CATEGORIES } from "config";

import sessionStorageService from "services/sessionStorageService";
import localize from "lang/localize";
import urlParse from "library/js/url";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  sessionKey: PropTypes.string,
  projectId: PropTypes.number /* Project ID */,
  id: PropTypes.number /* Category ID */,
  project: PropTypes.object.isRequired,
  projectImage: PropTypes.string,
  buttons: PropTypes.array,
  categories: PropTypes.array,
  topics: PropTypes.array.isRequired,
  more: PropTypes.bool,
  showcaseProject: PropTypes.object,
  popup: PropTypes.object,
  showPopup: PropTypes.bool.isRequired,
  handleClosePopupAlertDialog: PropTypes.func.isRequired,
  showShareGameDialog: PropTypes.bool.isRequired,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  handleCloseShareGameDialog: PropTypes.func.isRequired,
  checkpointChallengeId: PropTypes.number,
  showNextChallenge: PropTypes.bool,
  handleCloseNextChallengeDialog: PropTypes.func.isRequired,
  handleMore: PropTypes.func,
  user: PropTypes.object,
  team: PropTypes.object,
  notifications: PropTypes.number.isRequired,
  inboxUnread: PropTypes.number.isRequired,
  language: PropTypes.string,
  handleKeyPress: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCategorySelect: PropTypes.func,
};

const defaultProps = {
  more: false,
  projectId: null,
};

class ProjectPage extends Component {
  constructor() {
    super();
    this.state = {
      randomNumber: Math.floor(Math.random() * 3),
    };
    this.renderTopics = this.renderTopics.bind(this);
    this.renderTopicCategories = this.renderTopicCategories.bind(this);
  }

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("projectPage")) {
      bodyDOM.className += " projectPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("projectPage")) {
      bodyDOM.classList.remove("projectPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  getTopicLock(unlockable, locked) {
    if (unlockable && locked !== false) {
      return true;
    }
    return false;
  }

  renderTopicCategories() {
    if (SHOW_TOPIC_CATEGORIES && this.props.categories
      && Array.isArray(this.props.categories)
      && this.props.categories.length > 0) {
        let currentCategory = this.props.categories.filter(
          (category) =>
            category.hasOwnProperty("selected") && category.selected === true,
        )[0];

        return (
          <div className="category-chips chips">
            {/* Indicate all topics */}
            {!currentCategory && (
              <Fragment key={"topics-all"}>
                <button className="category-chip chip category-all button inline small active">
                  <span
                    className="category-title chip-title"
                    title={localize("category_all_text", this.props.language)}
                  >
                    {localize("category_all_text", this.props.language)}
                  </span>
                </button>
              </Fragment>
            )}
            {/* Show button to go to clear category selection */}
            {currentCategory && (
              <Fragment key={"topics-all"}>
                <Link
                  className="category-chip chip category-all button inline small inactive"
                  to={PROJECT_HOME.format(this.props.projectId)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleCategorySelect(0);
                  }}
                >
                  <span
                    className="category-title chip-title"
                    title={localize("category_all_text", this.props.language)}
                  >
                    {localize("category_all_text", this.props.language)}
                  </span>
                </Link>
              </Fragment>
            )}
            {/* Display current category at second position */}
            {currentCategory && (
              <Fragment key={this.props.id}>
                <button className="category-chip chip button inline small active">
                  <span
                    id={"categoryChip" + currentCategory.id}
                    className="category-title chip-title"
                    title={currentCategory.title}
                    dangerouslySetInnerHTML={{
                      __html: currentCategory.title,
                    }}
                  />
                </button>
              </Fragment>
            )}
            {/* Display other categories */}
            {this.props.categories.map(
              (category) =>
                (currentCategory == null || currentCategory.id !== category.id) && (
                  <Fragment key={category.id}>
                    <Link
                      id={"categoryChip" + category.id}
                      className="category-chip chip button inline small inactive"
                      to={TOPIC_CATEGORY.format(
                        this.props.projectId,
                        category.id,
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.handleCategorySelect(category.id);
                      }}
                    >
                      <span
                        className="category-title chip-title"
                        title={category.title}
                        dangerouslySetInnerHTML={{
                          __html: category.title,
                        }}
                      />
                    </Link>
                  </Fragment>
                ),
            )}
          </div>
        );
    } else {
      return null;
    }
  }

  /*
    isMobile bool prop determines if these Topic cards
    are rendered in mobile or desktop view
  */
  renderTopics(topics, isMobile) {
    return topics.map((topic, index) => (
      <div className="pure-u-1" key={"topicCard" + (isMobile ? "Mobile": "") + index}>
        <TopicCard
          sessionKey={this.props.sessionKey}
          projectId={this.props.projectId}
          isMobile={isMobile}
          allowReset={topic.allowReset || false}
          title={topic.title}
          description={topic.description}
          totalChallenges={topic.challengeNo}
          completedChallenges={topic.userChallengeCompletedNo || 0}
          img={topic.bannerImage}
          id={topic.id}
          started={topic.joined || false}
          locked={this.getTopicLock(topic.unlockable, topic.locked)}
          expired={typeof topic.expired === "boolean" ? topic.expired : false}
          completed={
            topic.challengeNo === topic.userChallengeCompletedNo || false
          }
          language={this.props.language}
        />
      </div>
    ));
  }

  renderAnnouncement() {
    // In API2, we use "alertTitle" instead of "title"
    if (this.props.project && this.props.project.projectNotice && this.props.project.projectNotice.title) {
      return (
        <div className="announcement">
          {this.props.project.projectNotice.title}
        </div>
      );
    } else {
      return null;
    }
  }

  renderPopupAlertDialog() {
    return (
      <PopupAlertDialog
        showDialog={true}
        handleClosePopupAlertDialog={this.props.handleClosePopupAlertDialog}
        popup={this.props.popup}
      />
    );
  }

  renderShareGameDialog() {
    return (
      <ShareGameDialog
        showDialog={this.props.showShareGameDialog}
        handleCloseDialog={this.props.handleCloseShareGameDialog}
        projectId={this.props.projectId}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }

  renderNextChallengeDialog() {
    return (
      <Modal
        isOpen={this.props.showNextChallenge}
        contentLabel={localize("continue_challenge_text", this.props.language)}
        onRequestClose={this.props.handleCloseNextChallengeDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("continue_challenge_text", this.props.language)}
            </h5>
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline rightmargin-10"
                onClick={this.props.handleCloseNextChallengeDialog}
                id="cancelButton"
              >
                {localize("alert_no_text", this.props.language)}
              </button>
              <button
                className="button inline cta"
                onClick={(e) => {
                  this.props.handleCloseNextChallengeDialog(e);
                  Router.navigate(
                    CHALLENGE.format(this.props.checkpointChallengeId),
                  );
                }}
                id="continueChallengeButton"
              >
                {localize("alert_yes_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const IS_640_DESKTOP_PROJECT =
      sessionStorageService.getItem("640_desktop_project") === "true";

    const showNextChallengePopup =
      this.props.checkpointChallengeId &&
      !this.props.showPopup &&
      localStorage.getItem("nextChallengePopupShown") !== "true";

    return (
      <div className="project container nopadding">
        {this.props.showPopup && this.renderPopupAlertDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        {showNextChallengePopup && this.renderNextChallengeDialog()}
        {this.renderAnnouncement()}
        <div
          className={
            "desktop-project innerblock verticalpadding horizontalpadding pure-g bottompadding-floating" +
            (IS_640_DESKTOP_PROJECT ? " hide-below-md-640" : " hide-below-md")
          }
        >
          {/* Render desktop Project page here */}
          <DesktopProjectPage
            isLoadingMore={this.props.isLoadingMore}
            categoryId={this.props.id}
            project={this.props.project}
            categories={this.props.categories}
            topics={this.props.topics}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            renderTopics={this.renderTopics}
            renderTopicCategories={this.renderTopicCategories}
            language={this.props.language}
            handleSubmit={this.props.handleSubmit}
            handleKeyPress={this.props.handleKeyPress}
            handleSearchChange={this.props.handleSearchChange}
          />
        </div>
        <div
          className={
            "mobile-project" +
            (IS_640_DESKTOP_PROJECT ? " hide-from-md-640" : " hide-from-md")
          }
        >
          {/* Render mobile Project page here */}
          <MobileProjectPage
            project={this.props.project}
            projectId={this.props.projectId}
            projectImage={this.props.projectImage}
            buttons={this.props.buttons}
            topics={this.props.topics}
            categories={this.props.categories}
            categoryId={this.props.id}
            user={this.props.user}
            team={this.props.team}
            notifications={this.props.notifications}
            inboxUnread={this.props.inboxUnread}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            handleOpenShareGameDialog={this.props.handleOpenShareGameDialog}
            renderTopics={this.renderTopics}
            renderTopicCategories={this.renderTopicCategories}
            language={this.props.language}
            handleSubmit={this.props.handleSubmit}
            handleKeyPress={this.props.handleKeyPress}
            handleSearchChange={this.props.handleSearchChange}
          />
        </div>
      </div>
    );
  }
}

ProjectPage.propTypes = propTypes;
ProjectPage.defaultProps = defaultProps;

export default ProjectPage;
