import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import RewardsPage from "./RewardsPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import localize from "lang/localize";

import getApiGenerator from "services/getApiGenerator";
import getApiGenerator2 from "services/getApiGenerator2";
import pushApiGenerator from "services/pushApiGenerator";
import {
  GET_TOPICS,
  GET_REWARDS,
  GET_REDEEMED,
  PURCHASE_ITEM,
  CANCEL_PURCHASE_ITEM,
  COLLECT_ITEM,
} from "services/api";
import {
  showAlertWithTimeout,
  setTopbar,
  setProfileDrawer,
  setProject,
  setButtons,
} from "actions";
import { LOAD_MORE_COUNT } from "config";
import listenerServices from "services/listenerServices";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: (project) => {
      dispatch(setProject(project));
    },
    setButtons: (buttons) => {
      dispatch(setButtons(buttons));
    },
    setTopbar: (info) => {
      dispatch(setTopbar(info));
    },
    setProfileDrawer: (info) => {
      dispatch(setProfileDrawer(info));
    },
    showAlertWithTimeout: (alert) => {
      dispatch(showAlertWithTimeout(alert));
    },
  };
};

export class RewardsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      currentCategory: "",
      rewards: null,
      rewardsCategories: null,
      rewardsCategoryData: [],
      rewardDescriptionAfter: "",
      redeemed: null,
      points: 0,
      loggedIn: !!props.user.id,
      searchInput: "",
      beganSearch: false,
      topics: null,
      error: null,
      code: null,
      more: false,
      rewardNotice: null,
      page: 1,
      isLoadingMore: false,
    };

    this.handleMore = this.handleMore.bind(this);
    this.getRedeemed = this.getRedeemed.bind(this);
  }

  componentDidMount() {
    // populate project in redux store
    if (this.props.projectId) {
      this.getProject();
    }

    // Load only data for active tab
    this.getRewards(1);
    this.setState({ current: "rewards" });

    window.addEventListener("scroll", this.handleMore);
  }

  /**
   * Call Project API to retrieve project information
   */
  getProject() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error,
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: "",
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  /**
   * Load more topics based on current page number
   */
  handleMore = debounce(() => {
    const {
      getRedeemed,
      state: { more, isLoadingMore, page },
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true,
        }));
        getRedeemed(page);
      }
    }
  }, 100);

  /**
   * Load list of available rewards
   * Note: Pagination not supported in api1
   */
  getRewards(page, category_id = "", keywords = null) {
    getApiGenerator(
      GET_REWARDS.format(this.props.projectId),
      {
        //page: page,
        //limit: LOAD_MORE_COUNT,
        category_id: category_id,
        keywords: keywords,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          if (category_id !== "") {
            this.setState({
              isLoadingMore: false,
              rewardsCategoryData: [],
            });
          } else {
            this.setState({
              isLoadingMore: false,
              rewards: [],
            });
          }
        }
      } else {
        const categories = res.body.categories
          ? res.body.categories.map((category) => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
            }))
          : null;
        this.setState({
          rewardsCategories: categories,
          points: res.body.pointsAvailableInBundle,
          rewardNotice: res.body.rewardNotice && res.body.rewardNotice.title ? res.body.rewardNotice.title	: null,
        });
        if (category_id === "") {
          this.setState({
            isLoadingMore: false,
            more: res.body.more,
            //page: res.body.more ? this.state.page + 1 : this.state.page,
            rewards:
              this.state.rewards && page !== 1
                ? this.state.rewards.slice().concat(res.body.data)
                : res.body.data,
          });
        } else {
          this.setState({
            isLoadingMore: false,
            more: res.body.more,
            //page: res.body.more ? this.state.page + 1 : this.state.page,
            rewardsCategoryData:
              this.state.rewards && page !== 1
                ? this.state.rewards.slice().concat(res.body.data)
                : res.body.data,
            rewardNotice: res.body.rewardNotice && res.body.rewardNotice.title ? res.body.rewardNotice.title	: null,
          });
        }
      }
    });
  }

  /**
   * Load list of redeemed rewards
   * BUG 1: Pagination not supported after switch to API2
   * BUG 2: Category filter is not supported in API
   */
  getRedeemed(page) {
    getApiGenerator2(
      GET_REDEEMED.format(this.props.user.id),
      {
        page: page,
        limit: LOAD_MORE_COUNT,
        project_id: this.props.projectId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            isLoadingMore: false,
            redeemed: [],
          });
        }
      } else {
        this.setState({
          isLoadingMore: false,
          more: res.body.more,
          page: res.body.more ? this.state.page + 1 : this.state.page,
          redeemed:
            this.state.redeemed && page !== 1
              ? this.state.redeemed.slice().concat(res.body.data)
              : res.body.data,
        });
      }
    });
  }

  /**
   * Update state for a given reward by ID
   * @param {int} id
   */
  updateRewards(id) {
    const rewards = this.state.rewards;
    let reward = rewards.filter((reward) => reward.id === id)[0];
    reward.ownedQuantity++;
    // eslint-disable-next-line
    reward.quantityAvailable ? reward.quantityAvailable-- : null;
    this.setState({ rewards: rewards });
  }

  /**
   * Handle reward page tab toggle views.
   *
   * @param {e} event
   */
  handleToggle = (event) => {
    this.setState({
      current: event.currentTarget.id,
      page: 1,
    });

    // Refresh the list in case data has changed
    if (event.currentTarget.id === "redeemed") {
      // Category filter is not supported by API
      this.getRedeemed(1);
    } else {
      this.getRewards(1, this.state.currentCategory);
    }
  };

  /**
   * Handle main action for a reward. (e.g. Redeem / Collect)
   *
   * @param {e} event - The click event
   * @param {int} id - Reward ID
   * @param {int} optionId - Reward option ID
   */
  handleClick(event, id, optionId = 0) {
    if (this.state.current === "rewards") {
      // In "Rewards" view
      this.setState({
        rewardDescriptionAfter: "",
      });
      this.handleRedeem(event, id, optionId);
    } else {
      // In "Redeemed" view
      this.handleCollect(event, id, optionId);
    }
  }

  /**
   * Cancel main action for a reward. (e.g. Redeem / Collect)
   * Note: Only cancel redemption is supported.
   *
   * @param {e} event - The click event
   * @param {int} id - Reward ID
   * @param {int} optionId - Reward option ID
   */
  handleCancelAction(event, id, optionId = 0) {
    if (this.state.current === "rewards") {
      // In "Rewards" view (No cancel action supported)
      return false;
    } else {
      // In "Redeemed" view (cancel redemption)
      this.handleCancelRedeem(event, id);
    }
  }

  /**
   * Execute redeem reward API call
   *
   * @param {e} event - The click event
   * @param {int} id - Reward ID
   * @param {int} optionId - Reward option ID
   */
  handleRedeem(event, id, optionId = 0) {
    event.preventDefault();
    pushApiGenerator(
      PURCHASE_ITEM,
      {
        type_id: id,
        reward_option_id: optionId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("buy_item_successful_text", this.props.language),
          type: "success",
        });
        this.setState({
          points: res.body.pointsAvailableInBundle,
          rewardDescriptionAfter: res.body.rewardDescriptionAfter,
        });
        this.props.setTopbar({ points: res.body.pointsAvailableInBundle });
        this.updateRewards(id);
        /*
         Redeemed list will be refreshed after changing tab.
         Hence, there is no need for API call here.
        */
      }
    });
  }

  /**
   * Execute collect reward API call
   *
   * @param {e} event - The click event
   * @param {int} id - Reward ID
   * @param {int} optionId - Reward option ID
   */
  handleCollect(event, id, optionId = 0) {
    event.preventDefault();
    getApiGenerator(
      COLLECT_ITEM,
      {
        type_id: id,
        rewardOptionId: optionId,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("collect_item_successful_text", this.props.language),
          type: "success",
        });

        if (this.state.redeemed) {
          // Refresh state of collected item
          // We cannot refresh the whole list since the item may not be on the first page
          let redemptions = this.state.redeemed.map((item) =>
            item.id === id
              ? {
                  ...item,
                  usedAtFormatted: localize(
                    "item_used_at_just_now_text",
                    this.props.language,
                  ),
                  rewardDescriptionAfterCollect:
                    res.body.rewardDescriptionAfterCollect,
                  rewardCode: res.body.rewardOption,
                }
              : item,
          );

          // This seems to cause issues with voucher code not being shown immediately after collection
          this.setState({
            redeemed: redemptions,
          });
        }
      }
    });
  }

  /**
   * Execute cancel reward redemption API call
   *
   * @param {e} event - The click event
   * @param {int} id - Reward ID
   */
  handleCancelRedeem(event, id) {
    event.preventDefault();
    getApiGenerator(
      CANCEL_PURCHASE_ITEM,
      {
        type_id: id,
      },
      this.props.sessionKey,
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error",
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize(
            "cancel_redeem_item_successful_text",
            this.props.language,
          ),
          type: "success",
        });

        if (this.state.redeemed) {
          // Remove item from array
          this.setState({
            redeemed: this.state.redeemed.filter((item) => item.id !== id),
          });
        }
      }
    });
  }

  /**
   * Handle category filter.
   *
   * @param {e} event
   */
  handleCategory(name, option) {
    this.setState({
      currentCategory: option.value,
      page: 1,
    });
    // Refresh the list in case data has changed
    // Category filter for redemption list is not supported by API
    this.getRewards(1, option.value);
  }

  /**
   * Handle rewards search.
   *
   * @param {e} event
   */
  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value });
  }

  /**
   * Handle form submit.
   *
   * @param {e} event
   */
  handleSubmit(event) {
    event.preventDefault();

    this.getRewards(
      this.state.page,
      this.state.currentCategory,
      this.state.searchInput,
    );
  }

  /**
   * Renders the component
   */
  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language,
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    }
    // if rewards are returned
    // OR there is at least 1 reward in the list, but can't find searched rewards
    else if (
      (this.state.rewards && this.state.rewards.length !== 0) ||
      (this.state.rewards && this.state.beganSearch)
    ) {
      return (
        <RewardsPage
          current={this.state.current}
          handleToggle={this.handleToggle}
          categories={this.state.rewardsCategories}
          points={this.state.points}
          onCategoryChange={this.handleCategory.bind(this)}
          rewards={
            this.state.currentCategory === ""
              ? this.state.rewards
              : this.state.rewardsCategoryData
          }
          announcement={this.state.rewardNotice}
          rewardDescriptionAfter={this.state.rewardDescriptionAfter}
          redeemed={this.state.redeemed}
          handleClick={this.handleClick.bind(this)}
          handleCancelAction={this.handleCancelAction.bind(this)}
          loggedIn={this.state.loggedIn}
          handleSearchChange={this.handleSearchChange.bind(this)}
          handleSubmit={this.handleSubmit.bind(this)}
          language={this.props.language}
          projectId={this.props.projectId}
          currentCategory={this.state.currentCategory}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          isLoadingMore={this.state.isLoadingMore}
        />
      );
    } else if (this.state.rewards) {
      return (
        <GenericErrorPage
          message={localize("empty_store_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsContainer);
