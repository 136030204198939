import React, { Component } from "react";
import PropTypes from "prop-types";

import { ENQUIRY_PAGE_LINK } from "config";
import deviceServices from "services/deviceServices";
import PostIframe from "../PostIframe/PostIframe";
import localize from "lang/localize";

const propTypes = {
  type: PropTypes.number.isRequired,
  id: PropTypes.string,
  projectId: PropTypes.number,
  sessionKey: PropTypes.string.isRequired,
  language: PropTypes.string,
};

class EnquiryPage extends Component {
  selectIframeTitle() {
    switch (this.props.type) {
      case 0: // challenge
        return localize("report_challenge", this.props.language);
      case 1: // player
        return localize("report_user", this.props.language);
      case 2: // completion
        return localize("report_submission", this.props.language);
      default:
        return localize("enquiry_text", this.props.language);
    }
  }

  render() {
    return (
      <div
        className={
          "fullscreen-frame-wrap" +
          (this.props.projectId ? " frame-project" : "") +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          title={this.selectIframeTitle()}
          hiddenFields={{
            session_key: this.props.sessionKey,
            lang: this.props.language,
            bundleId: this.props.projectId,
            enquiryType: this.props.type,
            objectId: this.props.id,
          }}
          url={ENQUIRY_PAGE_LINK}
        />
      </div>
    );
  }
}

EnquiryPage.propTypes = propTypes;

export default EnquiryPage;
